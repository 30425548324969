//variable
$boxShadowPanel : 0px 2px 6px -2px rgba(#00000029,0.5);
//$boxShadowPanel : 0px 2px 10px #00000029;

@import  'components/ModalLogin/ModalLogin';

.App {
  text-align: center;
  font-family: 'Roboto',sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  // background-color: #282c34;
  // min-height: 100vh;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
  // font-size: calc(10px + 2vmin);
  // color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App3DLibrary {
  background-color: #EFEFEF;
  color: gray;
  font-size: 13px;
}

.ReactModal__Overlay {
  z-index: 120; /* Must be greater than #side-dragbar:z-index */
  background-color: rgba(0, 0, 0, 0.75) !important;
}

.ReactModal__Content {
  width: 650px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 5px #00000029;
  border-radius: 8px;
  opacity: 1;
  text-align: left;
  font-size: 11px;
  letter-spacing: 0px;
  color: #707070;
}

