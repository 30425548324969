.AuthenticationModal {
  &.ReactModal__Content {
    position: absolute;
    top: 0;
    left: 50%;
    right: auto;
    bottom: auto;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    margin-right: -50%;
    transform: translate(-50%, 0);
    max-width: 440px;
    margin: 1.75rem auto;
  }
}

.AuthenticationModal--newsletter {

  &.ReactModal__Content {
    max-width: 800px;
  width: 80%;
  }
  
  /*
  &.ReactModal__Content {
    width: 80%;
    max-width: 1024px;
    padding: 0;
    border: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    font-size: 10.5px;

    @media only screen and (max-width: 1024px) {
      width: 90%;
      max-width: 512px;
      .NewsletterModal__imgBlock {
        display: none;
      }
      .NewsletterModal__contentBlock {
        width: 100%;
      }
    }
  }
  .AuthenticationModal__wrapper {
    padding: 0;
    .textInput__wrapper {
      min-height: 20px;
    }
    .AuthenticationModal__title > p,
    .AuthenticationModal__title > h1 {
      text-align: left;
    }

    .NewsletterModal__contentBlock {
      padding: 65px 50px 50px;
    }

    .AuthenticationModal__title > p {
      margin: 5px 0 30px;
    }

    .textInput__input {
      margin-bottom: 20px;
    }
  }*/
}

.AuthenticationModal.ReactModal__Content.AuthenticationModal--register {
  max-width: 800px;
  width: 80%;
}

.AuthenticationModal__wrapper {
  padding: 20px 30px;
  @media only screen and (max-width: 1024px) {
    padding: 20px 10px;
  }
}

.AuthenticationModal__title {
  text-align: center;
  color: #022d5a;
  margin-bottom: 20px;
  > h1 {
    margin: 0;
    font-weight: 700;
    font-size: 24px;
  }
  > p {
    margin: 10px 0;
    font-size: 14px;
  }
}

.AuthenticationModal__description {
  text-align: center;
  color: #022d5a;
}

.AuthenticationModal__message {
  margin: 32px 0;
  font-size: 14px;
  text-align: center;
  //color: #022d5a;
}

.AuthenticationModal__btnClose {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 28px;
  height: 28px;
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 4px;
  background: none;
  cursor: pointer;
  font-size: 20px;
  color: #022d5a;
  border: 0;
  &:hover {
    background-color: #eee;
  }
}

.AuthenticationModal__button {
  background-color: #022d5a;
  border: 1px solid #022d5a;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 15px;
  margin: auto;
  height: 40px;
  width: 100%;
  margin-top: 24px;
  &:hover {
    background-color: #021c37;
  }
}
.AuthenticationModal__note {
  margin-top: 15px;
  text-align: center;
}
.AuthenticationModal__link {
  color: #022d5a;
  cursor: pointer;
  font-weight: 500;
  &:hover {
    text-decoration: underline;
  }
}

.textInput {
  width: 100%;
}

.textInput__wrapper {
  position: relative;
  min-height: 68px;
  width: 100%;
  box-sizing: border-box;
}


.textInput__wrapper--policy {
  min-height: auto;

  padding-bottom: 18px;
}
.textInput__wrapper--spacer {
    padding: 0 10px;
  width: 100%;
  box-sizing: border-box;
}
.textInput__error {
  position: absolute;
  bottom:2px;
  padding-bottom: 2px;
  margin: 0;
  margin-top: 0px;
  color: #fe0000;
  font-size: 10px;
}
.textInput__policy {
  padding-bottom: 8px;
  margin: 0;
  padding-left: 6px;
  padding-top: 0;
  display: inline-block;
  a {
    color: #022d5a;
  }
}
.textInput__policy_error {
  padding-left: 6px;
}

.textInput__checkboxPolicy {
  width: 15px;
  height: 15px;
}

.textInput__policyBlock {
  display: flex;
  //margin-top: 16px;
  align-items: flex-start;
  & > label {
    padding-top: 2px;
  }
}

.textInput__input {
  border-radius: 3px;
  box-sizing: border-box;
  color: #222;
  font: 14px Helvetica;
  height: 50px;
  line-height: 17px;
  padding: 0 16px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #e5e5e5;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  overflow: scroll;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

//input
.textInput__errorMsg {
  padding: 4px 4px;
  margin: 0;
}

.textInput__passwordForgot {
  padding: 0;
  margin: 0;
  text-align: right;
  .AuthenticationModal__link {
    //color: #707070;
  }
}

.AuthenticationModal__policy {
  display: inline-block;
  margin-top: 16px;
  margin-bottom: 0;
  text-align: justify;
  font-size: 9px;
  a {
    color: #022d5a;
  }
}

.AuthenticationModal__rowForm {
  display: flex;
  margin-left: -10px;
  margin-right: -10px;
  .textInput__wrapper {
    padding: 0 10px;
  }
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
}

.AuthenticationModal__rowForm--password {
  border-top: 1px solid #e5e5e5;
}

.AuthenticationModal__rowForm--sep {
  border-top: 1px solid #e5e5e5;
  padding: 0 10px;
  margin-bottom: 26px;
}

.NewsletterModal {
  display: flex;
  box-sizing: border-box;
}

.NewsletterModal__img {
  width: 100%;
  display: block;
  height: 100%;
  object-fit: cover;
}
.NewsletterModal__imgBlock {
  width: 50%;
}
.NewsletterModal__contentBlock {
  box-sizing: border-box;
  width: 50%;
  padding: 60px 40px 40px 40px;
}

input[type="checkbox"] {
  accent-color: #022d5a;
  cursor: pointer;
}
